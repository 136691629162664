.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 10%;
  left: 10%;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}


.modal {
  z-index: 100;
  padding: 20px;
  width: 600px;
  background: white;
  position: relative;
  border-radius: 20px;
  height: 150px;
}

.modal-close {
    font-size: 20px;
    font-weight: 500;
    color: red;
    position: absolute;
    top: 0;
    right: 5%;
    z-index: 500;
}
